<template>
<div class="row card p-1 mt-4">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <p class="h5 p-3">{{ t('engflow',{},{locale:getLocal}) }}</p>
        </div>
        <div class="col-12">
          <hr class="m-0">
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-12" style="overflow:auto;">
              <div class="d-flex justify-content-center " style="    min-width: 600px;">
                <div class="main-img d-flex align-items-center">
                  <div>
                    <div class="d-flex justify-content-center">
                      <img src="/images/icon/solar.png" alt="solar">
                    </div>
                    <p class="text-val" style="color:#20C997;">{{pvData}} {{unitData}}</p>
                    <p class="text-name">{{ t('pv',{},{locale:getLocal}) }}</p>
                  </div>
                  
                </div>
                <div class="d-flex align-items-center">
                  <img class="d-block d-sm-block d-md-none" src="/images/icon/arrow-n.png" alt="arrow">
                  <img class="d-none d-sm-none d-md-block" src="/images/icon/arrowLong-n.png" alt="arrow">
                </div>
                <div class="main-img d-flex align-items-center">
                  <div>
                    <div class="d-flex justify-content-center">
                       <img src="/images/icon/inverter.png" alt="inverter">
                    </div>
                    <p class="text-center">{{ t('smstinverter',{},{locale:getLocal}) }}</p>
                  </div> 
                </div>
                <div class="d-flex align-items-center">
                  <img class="d-block d-sm-block d-md-none" src="/images/icon/arrow-n.png" alt="arrow">
                  <img class="d-none d-sm-none d-md-block" src="/images/icon/arrowLong-n.png" alt="arrow">
                </div>
                <div class="main-img d-flex align-items-center position-relative" style="padding:70px;">
                  <div >
                    <span class="circle-elec"></span>
                    <div class="d-flex justify-content-center">
                      <img class="" src="/images/icon/factory.png" alt="inverter">
                    </div>
                    <p class="text-val text-nowrap" style="color:#2C99FF;" >{{powerData}} {{unitData}}</p>
                    <p class="text-name text-nowrap">{{ t('power',{},{locale:getLocal}) }}</p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <img class="d-block d-sm-block d-md-none" src="/images/icon/arrow-p.png" alt="arrow">
                  <img class="d-none d-sm-none d-md-block" src="/images/icon/arrowLong-p.png" alt="arrow">
                </div>
                <div class="main-img d-flex align-items-center">
                  <div>
                    <div class="d-flex justify-content-center">
                      <img src="/images/icon/elec.png" alt="elec">
                    </div>
                    <p class="text-val"  style="color:#FF4D4F;">{{consumptionData}} {{unitData}}</p>
                    <p class="text-name">{{ t('cst',{},{locale:getLocal}) }}</p>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

</template> 

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import { useI18n } from 'vue-i18n';
export default {
  name: 'EnergyFlow',
  setup() {
      const {t, locale} = useI18n();
      return {t, locale};
  },
  components: {

  },
  data: function () {
    return {
        pvData:0,
        powerData:0,
        consumptionData:0,
        unitData:'',
    }
  },
  mounted() {
    this.getlistData()
    this.setInterval_listData()
  },
  methods: {
    async getlistData(){
      try {
        let data = {
          SIDE_ID:this.GET_plantId,
        }
        let getAPI = await serviceAPI.call_API('get','external/currentPowerFlow',data,'auth');
        let getData = getAPI.data.siteCurrentPowerFlow;
        this.pvData = getData.PV.currentPower;
        this.powerData = getData.LOAD.currentPower;
        this.consumptionData = getData.GRID.currentPower;
        this.unitData = getData.unit;
        // {
        //     "siteCurrentPowerFlow":{
        //        "updateRefreshRate":3,
        //        "unit":"kW",
        //        "connections":[
        //           {
        //              "from":"GRID",
        //              "to":"Load"
        //           }
        //        ],
        //        "GRID":{
        //           "status":"Active",
        //           "currentPower":9.2
        //        },
        //        "LOAD":{
        //           "status":"Active",
        //           "currentPower":9.2
        //        },
        //        "PV":{
        //           "status":"Idle",
        //           "currentPower":0.0
        //        }
        //     }
        //  }

      

      } catch (error) {
        // serviceMain.showErrorAlert(this,error)
      }
    },
    async setInterval_listData(){
      let _this = this;
      setInterval(function(){
         _this.getlistData()
      }, 10000);
    }
  },
  computed: {
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
    GET_plantId:function(){
      return this.$store.getters['localstorage/GET_plantId']
    },
    GET_inverterId:function(){
      return this.$store.getters['localstorage/GET_inverterId']
    },
    
  },
  watch: {
    GET_plantId: function (val) {
      this.getlistData()
    },

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-img{
  padding: 15px;
  img{
    width: 100%;
    max-width: 80px;
  }
  p{
    margin:0px;
    text-align: center;
  }
  
}
.text-name{

}
.text-val{
  text-align: center;
  font-weight: 600;
}
.circle-elec{
  position: absolute;
  top: 50%;
  left: 50%;
  border: 10px #2C99FF solid;
  border-radius: 50%;
  padding: 40%;
  transform: translate(-50% , -50%)
}
// @media only screen and (max-width: 1400px) {

// }
</style>
