<template>
<div class="row ">
    <div class="col-12">
      <PvsystChart :ChartData="ChartData" :Pvsyst="`${listData.PVSYSTBALANCE}`"  :Production="`${listData.PRODUCTIONBALANCE}`" :Unit="`${listData.UNIT}`" :key="reloadChart"/>
    </div>
</div>

</template> 

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import PvsystChart from '@/components/partial/PvsystChart.vue';
import { useI18n } from 'vue-i18n'

export default {
  name: 'ComparePvsystChart',
  setup() {
      const {t, locale} = useI18n();
      return {t, locale};
  },
  components: {
    PvsystChart
  },
  data: function () {
    return {
      listData:[],
      reloadChart:null,
      ChartData:{
        labels: [],
        datasets: [
          { 
            label: "Production",
            backgroundColor: "#20C997",
            data: []
          }, 
          {
            label: "Pvsyst",
            backgroundColor: "#352B6C",
            data: []
          }
        ]
      },
      ChartOptions:{
        barValueSpacing: 20,
        legend: {
          display: true,
          position: 'bottom',
          align: "start",
          labels: {
            usePointStyle:true,
            fontColor: '#5A5F7D',
            color:true,
            borderWidth: 0,
            
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              callback: function(value, index, values) {
                        return   value+'kWh';
                    }
            }
          }]
        }
      }
      
    }
  },
  mounted() {
     this.getlistData()
  },
  methods: {
    setChart(){

    },
    async getlistData(){
      try {
        let getTEXTSRC ='DAY';
        if(this.GET_dateType=='DAY'){
          getTEXTSRC = this.GET_dateToday;
        }
        if(this.GET_dateType=='MONTH'){
          getTEXTSRC = this.GET_dateMonth;
        }
        if(this.GET_dateType=='YEAR'){
          getTEXTSRC = this.GET_dateYear;
        }
        let data = {
          
          INVERTER_ID:this.GET_inverterId,
          SIDE_ID:this.GET_plantId,
          CHARTTYPE: this.GET_dateType,
          TEXTSRC:getTEXTSRC
        }
        let getAPI = await serviceAPI.call_API('get','dashboard/GetDashboardProductionChart',data,'auth');
        this.listData = getAPI.data.data[0]
        let getData = getAPI.data.data[0]
        this.ChartData.labels = JSON.parse(getData.LABEL)
        this.ChartData.datasets[0].data = JSON.parse(getData.PRODUCTIONCHART)
        this.ChartData.datasets[1].data = JSON.parse(getData.PVSYSTCHART)
        this.reloadChart= Date.now();
        
        // GASCO2: "1277.27"
        // LOCATION: "53/1/3 Moo 7 Suk Samat Road ,Tambon Na Pa Ampor Mueang Chonburi ,Changwa Thailand 20000"
        // PEAKPOW: "9.90"
        // PLANTNAME: "AJAWIT KALAWANTAVANICH"
        // TREES: "38.12258970"
        // UPD: "20210715 20:18"

      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    }
  },
  computed: {
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
    GET_plantId:function(){
      return this.$store.getters['localstorage/GET_plantId']
    },
    GET_inverterId:function(){
      return this.$store.getters['localstorage/GET_inverterId']
    },
    GET_dateToday:function(){
      return this.$store.getters['localstorage/GET_dateToday']
    },
    GET_dateMonth:function(){
      return this.$store.getters['localstorage/GET_dateMonth']
    },
    GET_dateYear:function(){
      return this.$store.getters['localstorage/GET_dateYear']
    },
    GET_dateType:function(){
      return this.$store.getters['localstorage/GET_dateType']
    },
    GET_socketSetReload:function(){
      return this.$store.getters['service/GET_socketSetReload']
    }
  },
  watch: {
    GET_plantId: function (val) {
      this.getlistData()
    },
    GET_dateToday: function (val) {
      if(this.GET_dateType=='DAY'){
        this.getlistData()
      }
    },
    GET_dateMonth: function (val) {
      if(this.GET_dateType=='MONTH'){
        this.getlistData()
      }
    },
    GET_dateYear: function (val) {
      if(this.GET_dateType=='YEAR'){
        this.getlistData()
      }
    },
    GET_dateType: function (val) {
        this.getlistData()
    },
    GET_socketSetReload: function (val) {
        this.getlistData()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// .card-progress{
//   background: #fff;
//   box-shadow: 0 10px 30px rgb(146 153 184 / 15%);
//   border-radius: 10px;
//   padding: 25px;
// }
.card-overview-progress{
  margin-top:0px !important;
  min-height: 50px !important;
  max-width: 100% !important;
  .card-body{
    margin-top:0px !important;
    padding:0px;
  }
}

.card-progress__summerys{
  margin-bottom: 10px;
}
.min-hight{
  min-height: 300px;
}
.cashflow-display__single{
  margin-bottom: 0px !important;
}
</style>
