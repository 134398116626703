<template>
<div class="row card">
    <div class="col-12 p-3 " >
          <p class="text-val m-0 " :style="{color:color}" >{{val}} {{unit}}</p>
          <p class="text-name m-0">{{name}}</p>
    </div>
</div>

</template> 

<script>
export default {
  name: 'CardIcon',
  props: [
    "name",
    "val",
    "unit",
    "color"
  ],
}
</script>
<style scoped>
p{
  
    text-align: center;
}
.text-val{
  font-weight: 600;
  font-size: 2rem;
  
}
.card{
  margin-top:15px ;
}
</style>
