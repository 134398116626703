<template>
<div class="row card p-1 mt-4">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <p class="h5 p-3">{{ t('powerandenergy',{},{locale:getLocal}) }}</p>
        </div>
        <div class="col-12">
          <hr class="m-0">
        </div>
        <div class="col-12">
          <div class="row my-3" >
            <div class="col-12 col-md-5">
              <div class="card card-overview-progress mt-0 border-0">     
                  <div class="card-body">
                      <div class="card-progress ">
                          <h4 class="mb-3">{{ t('production',{},{locale:getLocal}) }} &nbsp;&nbsp;&nbsp;<span class="font-green font-weight-bold">{{listDataflow.PRODUCTIONVALUE}}</span></h4>
                          <div class="card-progress__summerys d-flex justify-content-between">
                              <div>
                            
                                  <p>{{ t('selfconsum',{},{locale:getLocal}) }}: <span class="font-blue font-weight-bold">{{listDataflow.SELFCONSUMPVALUE}}</span></p>
                              </div>
                              <div>

                                  <p>{{ t('export',{},{locale:getLocal}) }}: <span  class="font-green font-weight-bold">{{listDataflow.PURCHASEDVALUE}}</span></p>
                              </div>
                          </div>
                          <div class="card-progress__bar">
                              <div class="progress bg-green">
                                  <div class="progress-bar bg-info" role="progressbar" :style="`width: ${listDataflow.SELFCONSUMPPERCENT}%`" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                              <div class="progress-excerpt">
                                  <p class="progress-total"> {{listDataflow.PURCHASEDPERCENT}}</p>
                                  <span class="progress-total">{{listDataflow.SELFCONSUMPPERCENT}}</span>
                              </div>
                          </div>
                      </div>
        
                      <div class="card-progress">
                          <h4 class="mb-3">{{ t('comsum',{},{locale:getLocal}) }} &nbsp;&nbsp;&nbsp;<span class="font-red font-weight-bold">{{listDataflow.CONSUMPVALUE}}</span></h4>
                          <div class="card-progress__summerys d-flex justify-content-between">
                              <div>
                                  <p>{{ t('selfconsum',{},{locale:getLocal}) }}: <span class="font-blue font-weight-bold">{{listDataflow.SELFCONSUMPVALUE}}</span></p>
                              </div>
                              <div>
                                  <p >{{ t('import',{},{locale:getLocal}) }}: <span  class="font-red font-weight-bold">{{listDataflow.IMPORTVALUE}}</span> </p>
                              </div>
                          </div>
                          <div class="card-progress__bar">
                              <div class="progress bg-red">
                                  <div class="progress-bar bg-info" role="progressbar" :style="`width: ${listDataflow.SELFCONSUMPPERCENT2}%`" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                              <div class="progress-excerpt">
                                  <p class="progress-total">{{listDataflow.PURCHASEDPERCENT2}}</p>
                                  <span class="progress-total">{{listDataflow.SELFCONSUMPPERCENT2}}</span>
                              </div>
                          </div>
                      </div>
           
                  </div>
              </div>
            </div>
            <div class="col-12 col-md-7">
              <ChartJs type="line" :data="ChartData" :option="ChartOptions" :key="reloadChart" />

            </div>
            
          </div>
        </div>
      </div>
    </div>
</div>

</template> 

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import ChartJs from '@/components/partial/ChartJs.vue';
import { useI18n } from 'vue-i18n';
export default {
  name: 'PowerEnergy',
  setup() {
      const {t, locale} = useI18n();
      return {t, locale};
  },
  components: {
    ChartJs
  },
  data: function () {
    return {
      listData:[],
      listDataflow:[],
      reloadChart:null,
      ChartData:{
        labels: [],
        datasets: [
          {
            label: "Production",
            data: [],
            lineTension: 0,
            fill: false,
            borderColor: '#20C997',
            backgroundColor: '#20C997',
            
          },
          {
            label: "Consumption",
            data: [],
            lineTension: 0,
            fill: false,
            borderColor: '#FF4D4F',
            backgroundColor: '#FF4D4F'
          },
          {
            label: "Self-consumption",
            data: [],
            lineTension: 0,
            fill: false,
            borderColor: '#2C99FF',
            backgroundColor: '#2C99FF'
          }
        ]
      },
      ChartOptions:{
        responsive: true,
        elements: {
            point:{
                radius: 0
            }
        },
        legend: {
          display: true,
          position: 'bottom',
          align: "start",
          labels: {
            usePointStyle:true,
            fontColor: '#5A5F7D',
            color:true,
            borderWidth: 0,
            
          }
        },
        scales: {
          // xAxes: [{
          //   ticks: {
          //     maxRotation: 90,
          //     minRotation: 80
          //   }
          // }],
          yAxes: [{
            ticks: {
              min: 0,
              // max: 100,
              // stepSize: 20,
              callback: function(value, index, values) {
                        return   value+'kWh';
                    }
            }
          }]
        }
      }
      
    }
  },
  mounted() {
    this.getlistData()
    this.getlistDataflow()
  },
  methods: {

    async getlistData(){
      try {
        let getTEXTSRC ='DAY';
        if(this.GET_dateType=='DAY'){
          getTEXTSRC = this.GET_dateToday;
        }
        if(this.GET_dateType=='MONTH'){
          getTEXTSRC = this.GET_dateMonth;
        }
        if(this.GET_dateType=='YEAR'){
          getTEXTSRC = this.GET_dateYear;
        }
        let data = {
          INVERTER_ID:this.GET_inverterId,
          SIDE_ID:this.GET_plantId,
          CHARTTYPE: this.GET_dateType,
          TEXTSRC:getTEXTSRC
        }
        let getAPI = await serviceAPI.call_API('get','dashboard/GetDashboardDataEnergyChart',data,'auth');
        let getData = getAPI.data.data[0]
        this.ChartData.labels = JSON.parse(getData.LABEL)
        this.ChartData.datasets[0].data = JSON.parse(getData.PRODUCTIONDATA)
        this.ChartData.datasets[1].data = JSON.parse(getData.COMPDATA)
        this.ChartData.datasets[2].data = JSON.parse(getData.SELFCOMPDATA)
        this.reloadChart= Date.now();
        // this.listData = getAPI.data.data[0]
        // data: Array(1)
        // 0:
        // COMPDATA: "[0.00, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000]"
        // LABEL: "[\"00:00\",\"01:00\",\"02:00\",\"03:00\",\"04:00\",\"05:00\",\"06:00\",\"07:00\",\"08:00\",\"09:00\",\"10:00\",\"11:00\",\"12:00\",\"13:00\",\"14:00\",\"15:00\",\"16:00\",\"17:00\",\"18:00\",\"19:00\",\"20:00\",\"21:00\",\"22:00\",\"23:00\",\"23:59\"]"
        // PRODUCTIONDATA: "[0.00, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000]"
        // SELFCOMPDATA: "[0.00, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000, 0.000]"


      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async getlistDataflow(){
      try {
        let getTEXTSRC ='DAY';
        if(this.GET_dateType=='DAY'){
          getTEXTSRC = this.GET_dateToday;
        }
        if(this.GET_dateType=='MONTH'){
          getTEXTSRC = this.GET_dateMonth;
        }
        if(this.GET_dateType=='YEAR'){
          getTEXTSRC = this.GET_dateYear;
        }
        let data = {
          INVERTER_ID:this.GET_inverterId,
          SIDE_ID:this.GET_plantId,
          CHARTTYPE: this.GET_dateType,
          TEXTSRC:getTEXTSRC
        }
        console.log(data)
        let getAPI = await serviceAPI.call_API('get','dashboard/GetDashboardDataEnergy',data,'auth');
        console.log(getAPI)
        this.listDataflow = getAPI.data.data[0]

      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    }
  },
  computed: {
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
    GET_plantId:function(){
      return this.$store.getters['localstorage/GET_plantId']
    },
    GET_inverterId:function(){
      return this.$store.getters['localstorage/GET_inverterId']
    },
    GET_dateToday:function(){
      return this.$store.getters['localstorage/GET_dateToday']
    },
    GET_dateMonth:function(){
      return this.$store.getters['localstorage/GET_dateMonth']
    },
    GET_dateYear:function(){
      return this.$store.getters['localstorage/GET_dateYear']
    },
    GET_dateType:function(){
      return this.$store.getters['localstorage/GET_dateType']
    },
    GET_socketSetReload:function(){
      return this.$store.getters['service/GET_socketSetReload']
    }
  },
  watch: {
    GET_plantId: function (val) {
      this.getlistData()
      this.getlistDataflow()
    },
    GET_dateToday: function (val) {
      if(this.GET_dateType=='DAY'){
        this.getlistData()
        this.getlistDataflow()
      }
    },
    GET_dateMonth: function (val) {
      if(this.GET_dateType=='MONTH'){
        this.getlistData()
        this.getlistDataflow()
      }
    },
    GET_dateYear: function (val) {
      if(this.GET_dateType=='YEAR'){
        this.getlistData()
        this.getlistDataflow()
      }
    },
    GET_dateType: function (val) {
        this.getlistData()
        this.getlistDataflow()
    },
    GET_socketSetReload: function (val) {
        this.getlistData()
        this.getlistDataflow()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// .card-progress{
//   background: #fff;
//   box-shadow: 0 10px 30px rgb(146 153 184 / 15%);
//   border-radius: 10px;
//   padding: 25px;
// }
.card-overview-progress{
  margin-top:0px !important;
  min-height: 50px !important;
  max-width: 100% !important;
  .card-body{
    margin-top:0px !important;
    padding:0px;
  }
}

.card-progress__summerys{
  margin-bottom: 10px;
}
</style>
