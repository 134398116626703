<template>
<div class="row mt-4">
    <div class="col-6 col-md-3 ">
        <CardData :name="t('tdsumeng',{},{locale:getLocal})" :val="listData.EGTODAY?listData.EGTODAY:'-'" unit="" color="#20C997" />
    </div>
    <div class="col-6 col-md-3 ">
        <CardData :name="t('msumeng',{},{locale:getLocal})" :val="listData.EGMONTH?listData.EGMONTH:'-'" unit="" color="#20C997" />
    </div>
    <div class="col-6 col-md-3 ">
        <CardData :name="t('lifetimeeng',{},{locale:getLocal})" :val="listData.EGLIFETIME?listData.EGLIFETIME:'-'" unit="" color="#2C99FF" />
    </div>
    <div class="col-6 col-md-3 ">
        <CardData :name="t('lifetimervn',{},{locale:getLocal})" :val="listData.LTREVENUE?`${listData.LTREVENUE}`:'-'" unit="" color="#2C99FF" />
    </div>
</div>

</template> 

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import CardData from '@/components/partial/CardData.vue';
import { useI18n } from 'vue-i18n'

export default {
  name: 'DashboardEnergy',
  setup() {
      const {t, locale} = useI18n();
      return {t, locale};
  },
  components: {
    CardData
  },
  data: function () {
    return {
      listData:[]
    }
  },
  mounted() {
    this.getlistData()
  },
  methods: {
    async getlistData(){
      try {
        let getTEXTSRC ='DAY';
        if(this.GET_dateType=='DAY'){
          getTEXTSRC = this.GET_dateToday;
        }
        if(this.GET_dateType=='MONTH'){
          getTEXTSRC = this.GET_dateMonth;
        }
        if(this.GET_dateType=='YEAR'){
          getTEXTSRC = this.GET_dateYear;
        }
        let data = {
          
          INVERTER_ID:this.GET_inverterId,
          SIDE_ID:this.GET_plantId,
          CHARTTYPE: this.GET_dateType,
          TEXTSRC:getTEXTSRC
        }
        let getAPI = await serviceAPI.call_API('get','dashboard/GetDashboardSumEnergy',data,'auth');
        this.listData = getAPI.data.data[0]
        // EGLIFETIME: "4666668.000000"
        // EGMONTH: "619252.000000"
        // EGTODAY: null

      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    }
  },
  computed: {
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
    GET_plantId:function(){
      return this.$store.getters['localstorage/GET_plantId']
    },
    GET_inverterId:function(){
      return this.$store.getters['localstorage/GET_inverterId']
    },
    GET_dateToday:function(){
      return this.$store.getters['localstorage/GET_dateToday']
    },
    GET_dateMonth:function(){
      return this.$store.getters['localstorage/GET_dateMonth']
    },
    GET_dateYear:function(){
      return this.$store.getters['localstorage/GET_dateYear']
    },
    GET_dateType:function(){
      return this.$store.getters['localstorage/GET_dateType']
    },
    GET_socketSetReload:function(){
      return this.$store.getters['service/GET_socketSetReload']
    }
  },
  watch: {
    GET_plantId: function (val) {
      this.getlistData()
    },
    GET_dateToday: function (val) {
      if(this.GET_dateType=='DAY'){
        this.getlistData()
      }
    },
    GET_dateMonth: function (val) {
      if(this.GET_dateType=='MONTH'){
        this.getlistData()
      }
    },
    GET_dateYear: function (val) {
      if(this.GET_dateType=='YEAR'){
        this.getlistData()
      }
    },
    GET_dateType: function (val) {
        this.getlistData()
    },
    GET_socketSetReload: function (val) {
        this.getlistData()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
