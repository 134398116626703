<template>
<div class="row card p-0 mt-4">
  <div class="col-12 p-0">
    <div class="row p-3">
      <div class="col-12">
        <p class="title-card">{{ t('pdt',{},{locale:getLocal}) }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 ">
        <table>
          <tr>
            <td class="font-weight-bold" colspan="2">{{ t('day',{},{locale:getLocal}) }}</td>
            <td class="font-weight-bold">{{ t('pvsy',{},{locale:getLocal}) }}</td>
            <td class="font-weight-bold">{{ t('pdtn',{},{locale:getLocal}) }}</td>
            <td class="font-weight-bold">{{ t('shine',{},{locale:getLocal}) }}</td>
          </tr>
          <tr v-for="(e,index) in listData.slice(0, 15)" :key="e.days">
            <td class="weather"><img class="w-100" :src="e.weather" alt=""></td>
            <td>{{e.days}}</td>
            <td>{{e.pvsyst}}</td>
            <td :class="`${e.product<e.pvsyst?'td-red':'td-green'}`">{{e.production}}</td>
            <td>{{e.direct_irradiance}}</td>
          </tr>
        </table>
      </div>
      <div class="col-12 col-md-6 ">
        <table>
          <tr>
            <td class="font-weight-bold" colspan="2">{{ t('day',{},{locale:getLocal}) }}</td>
            <td class="font-weight-bold">{{ t('pvsy',{},{locale:getLocal}) }}</td>
            <td class="font-weight-bold">{{ t('pdtn',{},{locale:getLocal}) }}</td>
            <td class="font-weight-bold">{{ t('shine',{},{locale:getLocal}) }}</td>
          </tr>
          <tr v-for="(e,index) in listData.slice(15, 99)" :key="e.date">
            <td class="weather"><img class="w-100" :src="e.weather" alt=""></td>
            <td>{{e.days}}</td>
            <td>{{e.pvsyst}}</td>
            <td :class="`${e.product<e.pvsyst?'td-red':'td-green'}`">{{e.production}}</td>
            <td>{{e.direct_irradiance}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <!--  -->
</div>

</template> 

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import { useI18n } from 'vue-i18n';
export default {
  name: 'ProductTable',
  setup() {
      const {t, locale} = useI18n();
      return {t, locale};
  },
  components: {
  },
  data: function () {
    return {
        listData:[]
    }
  },
  mounted() {
     this.getlistData()
  },
  methods: {
    async getlistData(){
      try {
        let getTEXTSRC ='MONTH';
        
        if(this.GET_dateType=='MONTH'){
          getTEXTSRC = this.GET_dateMonth;
        }
        
        let data = {
          
          INVERTER_ID:this.GET_inverterId,
          SIDE_ID:this.GET_plantId,
          CHARTTYPE: this.GET_dateType,
          TEXTSRC:getTEXTSRC
        }
        let getAPI = await serviceAPI.call_API('get','dashboard/GetDashboardProduction',data,'auth');
        this.listData = getAPI.data.data
        // GASCO2: "1277.27"
        // LOCATION: "53/1/3 Moo 7 Suk Samat Road ,Tambon Na Pa Ampor Mueang Chonburi ,Changwa Thailand 20000"
        // PEAKPOW: "9.90"
        // PLANTNAME: "AJAWIT KALAWANTAVANICH"
        // TREES: "38.12258970"
        // UPD: "20210715 20:18"

      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    }
  },
  computed: {
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
    GET_plantId:function(){
      return this.$store.getters['localstorage/GET_plantId']
    },
    GET_inverterId:function(){
      return this.$store.getters['localstorage/GET_inverterId']
    },
    GET_dateMonth:function(){
      return this.$store.getters['localstorage/GET_dateMonth']
    },
    GET_dateType:function(){
      return this.$store.getters['localstorage/GET_dateType']
    },
    GET_socketSetReload:function(){
      return this.$store.getters['service/GET_socketSetReload']
    }
  },
  watch: {
    GET_plantId: function (val) {
      this.getlistData()
    },
    GET_dateMonth: function (val) {
      if(this.GET_dateType=='MONTH'){
        this.getlistData()
      }
    },
    GET_dateType: function (val) {
        this.getlistData()
    },
    GET_socketSetReload: function (val) {
        this.getlistData()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
table {
  width: 100% ;
  border-collapse: collapse !important;
  
}
table, td, th {
  border: 1px solid #F1F2F6;
  // border: 2px #F1F2F6 solid !important;
  text-align: center;
}
td{
  color: #5A5F7D !important;
  padding: 5px 0px !important;
  font-size: 0.9rem !important;
}
.weather{
  padding: 7px !important;
  width: 40px;
}
.td-red{
  color: #FF4D4F !important;
  background-color: #FDEEEE;
}
.td-green{
  color: #20C997 !important;
}
</style>
