<template>
        <div :class="`${($store.getters['display/getAside'])?'contents expanded':'contents'}`">

            <div class="container-fluid">
                <div class="social-dash-wrap">
                    <div class="row">
                        <div class="col-12">
                            <BreadCrumbDate defaultType="MONTH" namePage="Monthly Report" :hideToday="true" :hideYear="true" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-3">
                          <CompanyDetail />
                          <SelectPlant />
                          <MonthlyDetail/>
                          <NoFullLoad />
                        </div>
                        <div class="col-12 col-md-9">
                          <ProductTable/>
                          <ComparePvsystChart/>
                          <GraphsNotCal/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
</template>

<script>
// @ is an alias to /src
import BreadCrumbDate from '@/components/main/BreadCrumbDate.vue';
import CompanyDetail from '@/components/main/CompanyDetail.vue';
import SelectPlant from '@/components/main/SelectPlant.vue';
import MonthlyDetail from '@/components/monthly/MonthlyDetail.vue';
import NoFullLoad from '@/components/monthly/NoFullLoad.vue';
import ProductTable from '@/components/monthly/ProductTable.vue';
import ComparePvsystChart from '@/components/monthly/ComparePvsystChart.vue';
import GraphsNotCal from '@/components/monthly/GraphsNotCal.vue';
export default {
  name: 'Monthly',
  components: {
    BreadCrumbDate,
    CompanyDetail,
    SelectPlant,
    MonthlyDetail,
    NoFullLoad,
    ProductTable,
    ComparePvsystChart,
    GraphsNotCal
  },
  data: function () {
    return {
        selectedMonth:null
      }
    },
  mounted() {
    this.$store.dispatch('display/SET_pageName',"Monthly Report")
  },
  methods: {
 
  }
}

</script>